export var PlayingState;
(function (PlayingState) {
    PlayingState[PlayingState["loading"] = 0] = "loading";
    PlayingState[PlayingState["playing"] = 1] = "playing";
    PlayingState[PlayingState["paused"] = 2] = "paused";
    PlayingState[PlayingState["idle"] = 3] = "idle";
    PlayingState[PlayingState["buffering"] = 4] = "buffering";
    PlayingState[PlayingState["error"] = 5] = "error";
})(PlayingState || (PlayingState = {}));
export class PlayerLogPayload {
}
// generic abstraction of player events that are taken from the video player, Chromecast and mobile implementations
export var PlayerEventTypes;
(function (PlayerEventTypes) {
    PlayerEventTypes["playStart"] = "playStart";
    PlayerEventTypes["playing"] = "playing";
    PlayerEventTypes["pause"] = "pause";
    PlayerEventTypes["error"] = "error";
    PlayerEventTypes["stopped"] = "stopped";
    PlayerEventTypes["timeupdate"] = "timeupdate";
    PlayerEventTypes["textTrackChanged"] = "textTrackChanged";
    PlayerEventTypes["audioTrackChanged"] = "audioTrackChanged";
})(PlayerEventTypes || (PlayerEventTypes = {}));
export var PlayerDeviceTypes;
(function (PlayerDeviceTypes) {
    PlayerDeviceTypes["chromecast"] = "chromecast";
    PlayerDeviceTypes["default"] = "";
})(PlayerDeviceTypes || (PlayerDeviceTypes = {}));
export var PlayerEventTypePayloads;
(function (PlayerEventTypePayloads) {
    PlayerEventTypePayloads["play"] = "play";
    PlayerEventTypePayloads["playing"] = "playing";
    PlayerEventTypePayloads["paused"] = "paused";
    PlayerEventTypePayloads["stop"] = "stop";
    PlayerEventTypePayloads["error"] = "error";
    PlayerEventTypePayloads["configure"] = "configure";
})(PlayerEventTypePayloads || (PlayerEventTypePayloads = {}));
export var PulseMode;
(function (PulseMode) {
    PulseMode["live"] = "live";
    PulseMode["archive"] = "archive";
    PulseMode["offline"] = "offline";
})(PulseMode || (PulseMode = {}));
