import { graphRequest } from './graph-request';
import { articleAssetPlayMutation, articleQuery } from './queries';
import { toArticle, toPlayConfig } from './converters';
export class ApiService {
    constructor(baseUrl, projectId) {
        this.apiFetchUrl = `${baseUrl}/graphql/${projectId}`;
        this.token = null;
    }
    setToken(token) {
        this.token = token;
    }
    getArticleAssetPlayConfig(articleId, assetId, continueFromPreviousPosition) {
        return graphRequest(this.apiFetchUrl, articleAssetPlayMutation, { articleId, assetId, protocols: ['dash', 'hls'] }, this.token).then((response) => {
            if (!response || !response.data || response.errors) {
                const { message, code } = response.errors[0];
                throw { message, code }; // @TODO to play config error
            }
            return toPlayConfig(response.data.ArticleAssetPlay, continueFromPreviousPosition);
        });
    }
    getArticle(articleId) {
        return graphRequest(this.apiFetchUrl, articleQuery, { articleId }, this.token).then((response) => {
            if (!response || !response.data || response.errors) {
                const { message, code } = response.errors[0];
                throw { message, code };
            }
            return toArticle(response.data.Article);
        });
    }
}
