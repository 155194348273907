export function graphRequest(apiFetchUrl, query, variables, token) {
    const authHeader = token ? { Authorization: 'Bearer ' + token } : {};
    return fetch(apiFetchUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...authHeader,
        },
        body: JSON.stringify({
            query,
            variables,
        }),
    }).then(response => response.json());
}
