import { PlayerLogProcessor } from './player-log-processor';
import { PlayerEventTypes, PlayingState } from '../models/player';
export class PlayerLoggerService {
    constructor(baseUrl, projectId) {
        this.intervalHandle = 0;
        this.playerLogProcessor = new PlayerLogProcessor(baseUrl, projectId);
        this.reset();
    }
    init() {
        this.playerLogProcessor.init();
    }
    destroy() {
        this.playerLogProcessor.destroy();
    }
    onStart(pulseToken, deviceType, localTimeDelta, isLive, onStopCallback) {
        this.reset();
        this.playSession = {
            pulseToken,
            deviceType,
            eventStack: [],
            localTimeDelta,
            isLive,
            onStopCallback,
        };
    }
    onCurrentTimeUpdated(currentTime) {
        this.playerProperties.playPosition = currentTime;
        if (this.playerProperties.mediaDuration > 0 && this.playerProperties.state !== PlayingState.idle) {
            this.logEvent(PlayerEventTypes.timeupdate);
        }
    }
    onDurationUpdated(duration) {
        this.playerProperties.mediaDuration = duration;
    }
    onPlaying() {
        if (this.playerProperties.state !== PlayingState.playing) {
            if (this.playerProperties.state === PlayingState.idle) {
                this.playerProperties.state = PlayingState.playing;
                this.logEvent(PlayerEventTypes.playStart);
                this.processPlaySession();
                this.startInterval();
            }
            else {
                this.playerProperties.state = PlayingState.playing;
                this.logEvent(PlayerEventTypes.playing);
            }
        }
    }
    onPause() {
        if (this.playerProperties.state !== PlayingState.paused && this.playerProperties.state !== PlayingState.idle) {
            this.playerProperties.state = PlayingState.paused;
            this.logEvent(PlayerEventTypes.pause);
        }
    }
    onError(error) {
        if (this.playerProperties.state !== PlayingState.error) {
            this.playerProperties.state = PlayingState.error;
            this.playerProperties.error = error;
            this.logEvent(PlayerEventTypes.error);
        }
    }
    onStop() {
        if (this.playerProperties.state !== PlayingState.idle) {
            this.playerProperties.state = PlayingState.idle;
            this.logEvent(PlayerEventTypes.stopped);
            this.stopInterval();
            this.processPlaySession();
        }
    }
    onTextTrackChanged(textTrack) {
        if (this.playerProperties.state === PlayingState.idle) {
            return;
        }
        this.playerProperties.textTrack = textTrack;
        this.logEvent(PlayerEventTypes.textTrackChanged);
    }
    onAudioTrackChanged(audioTrack) {
        if (this.playerProperties.state === PlayingState.idle) {
            return;
        }
        this.playerProperties.audioTrack = audioTrack;
        this.logEvent(PlayerEventTypes.audioTrackChanged);
    }
    updateProperties(playerProperties) {
        this.playerProperties = {
            ...this.playerProperties,
            ...playerProperties,
        };
    }
    startInterval() {
        this.stopInterval();
        // @ts-ignore
        this.intervalHandle = setInterval(() => {
            this.processPlaySession();
        }, 30000);
    }
    stopInterval() {
        if (this.intervalHandle) {
            clearInterval(this.intervalHandle);
        }
    }
    processPlaySession() {
        this.playerLogProcessor.processPlaySession({ ...this.playSession }, this.getTimeStamp());
        this.playSession.eventStack = [];
    }
    logEvent(eventType) {
        if (this.playSession) {
            this.playSession.eventStack.push({
                ...this.playerProperties,
                eventType,
                timeStamp: this.getTimeStamp(),
            });
        }
    }
    reset() {
        this.playSession = null;
        this.playerProperties = {
            state: PlayingState.idle,
            error: null,
            mediaDuration: 0,
            playPosition: 0,
            audioTrack: null,
            textTrack: null,
        };
    }
    getTimeStamp() {
        return Date.now() - (this.playSession ? this.playSession.localTimeDelta : 0);
    }
}
