const Component = videojs.getComponent('component');
export class ChromecastButton extends Component {
    constructor(player, options) {
        super(player, options);
    }
    createEl() {
        const el = super.createEl();
        const castEl = document.createElement('button', 'google-cast-button');
        castEl.className = 'vjs-chromecast-button';
        el.appendChild(castEl);
        return el;
    }
}
